/*
 * Application-wide styles
 */

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.login-page {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* To match the adjustment of a fluid container in navbar. */
@media (max-width: 767.98px) {
  #content-container {
    padding: 0;
  }
  .navbar-collapse {
    padding-top: 20px;
  }
}

/* Put the footer near the bottom of viewport when the content does not
   fill the page. Putting it beyond bottom causes links to scroll incorrectly. */
#content-container {
  min-height: 70vh;
}

.icon {
  margin: 3px 0 0 0;
}

.icon.text-success {
  fill: #28a745;
}

.btn-outline-primary .icon {
  fill: #007bff;
}
.btn-outline-secondary .icon {
  fill: #6c757d;
}
.btn-outline-primary:hover .icon,
.btn-outline-secondary:hover .icon {
  fill: #ffffff;
}

/* TCF brand blue */
.bg-dark {
  background-color: #000033 !important;
}

/* Not necessarily TCF sanctioned, but a different background color to make it more obvious a user is in the data entry app. */
.bg-data-entry {
  background-color: #660000 !important;
}

.react-pdf__Page canvas {
  border: 1px #6c757d solid;
  box-sizing: border-box;
  margin-bottom: 1rem;
}

.redux-toastr .top-center {
  min-width: 50vw;
}

/* So we can nest <Button><Link to=""/></Button> */
.btn a {
  color: inherit;
}

/* react-widgets multiselect */
.rw-multiselect-tag > *,
.rw-list-option {
  font-size: 80%;
}

.btn {
  margin-bottom: .5rem;
}

.contentPage {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.contentPageHeader {
    position: sticky;
    top: 0;
    z-index: 10;
    width: 100%;
}

.contentPageMain {
    flex: 1 0 auto;
    min-height: 90vh;
}

.flexPage {
    display: flex;
}

.adminSidebarContainer {
    width: 500px;
    min-width: 350px;
    position: relative;
    margin-top: -99px;
    background-color: #f7f7f7;
}

.sidebarContainer {
    border-right: 1px solid #e8e8e8;
    width: 300px;
    min-width: 250px;
    position: relative;
    margin-top: -99px;
    background-color: #f7f7f7;
}

.sidebarContents {
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* overflow-y: auto; */
    position: sticky;
    top: 0;
    padding-top: 110px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    overflow-x: hidden;
}

.mainContent {
    flex-grow: 1;
    padding: 1rem 1rem 11rem 1rem;
    width: 100%;
    overflow-x: hidden;
}

.accordion {
    background-color: #f7f7f7;
    cursor: pointer;
}

.accordion:hover {
    background-color: #ddd;
}

table {
    font-size: .75rem;
}

/* Patch for bug that displays page list even when not supposed to */
.react-bootstrap-table-pagination-list-hidden {
    display: none;
}

.react-bootstrap-table table {
    table-layout: auto;
}

.breadcrumbContainer {
    font-size: 0.8rem;
    border-bottom: 1px solid #e8e8e8;
}

.breadcrumbContainer ol {
    background-color: white;
    margin-bottom: 0;
}

.breadcrumb {
    padding: 0.75rem 1rem;
}

.tableButton {
    font-size: 0.75rem;
    margin: 0 0 0 0.75rem;
    padding: 0;
}

.nav-tabs .nav-link {
    cursor: pointer;
}

.nav-link {
  padding: 0.25rem 0.75rem;
}

.merge-cell {
    cursor: pointer;
}

.merge-cell:hover {
    background-color: #ddd;
}

.collision-card input,
.collision-card .input-group-prepend,
.collision-card-address-or-contact {
    cursor: pointer;
}

.collision-card-address-or-contact {
    flex-direction: column;
    border: 1px solid #ced4da;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.cursor-wait {
    cursor: wait;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.merge-table {
    border: 1px solid #e8e8e8;
}

.merge-row .mc-selected {
    background-color: #e6eef7;
}

.merge-row .mc-direction {
    color: #999999;
}

.merge-row .mc-merged {
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
}

.merge-row th {
    border-right: 1px solid #e8e8e8;
}

.merge-row .mc-direction.mc-point-right:not(.mc-selected)::after {
    content: '\00a0\00a0';
}

.merge-row .mc-direction.mc-point-left:not(.mc-selected)::after {
    content: '\00a0\00a0';
}

.merge-row .mc-direction.mc-point-right.mc-selected::after {
    content: '»';
}

.merge-row .mc-direction.mc-point-left.mc-selected::after {
    content: '«';
}

.adminPage .react-bootstrap-table th {
    font-size: 0.65rem;
    font-weight: normal;
    text-transform: uppercase;
}

.field-label {
    color: black;
    font-weight: normal;
    font-size: 0.65rem;
    text-transform: uppercase;
    margin-bottom: 0.2rem;
}
