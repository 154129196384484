.rd3t-node circle, polygon {
  fill: #ffffff;
  stroke: #333;
  stroke-width: 1;
  stroke-opacity: 1;
}

.rd3t-node polygon.current {
  cursor: grab;
}

.rd3t-svg {
  border: 1px solid #dee2e6;
  background-color: #fafafa;
}

.rd3t-label__title {
  fill: #007bff;
  stroke-width: 0;
  stroke-opacity: 0;
}

.rd3t-label__title.current {
  cursor: grab;
  fill: #333;
}
